import { Link } from 'gatsby';
import React from 'react';
import Rehype from 'rehype-react';
const { urlRedirects } = require('../meta');

export default ({ data, pageContext }) => {
  const doc = data.markdownRemark;
  const getLast = () => typeof window !== 'undefined' && window.location.pathname.replace(/\//g, '');

  function h1(props) {
    return (
      <h1 className="text-2xl font-normal pt-8" {...props}>
        {props.children}
      </h1>
    );
  }

  function h2(props) {
    return (
      <h2 className="text-2xl font-normal py-4" {...props}>
        {props.children}
      </h2>
    );
  }

  function h3(props) {
    return (
      <h3 className="text-lg font-normal py-4" {...props}>
        {props.children}
      </h3>
    );
  }

  function p(props) {
    return (
      <p className="font-normal text-black py-2 relative" {...props}>
        {props.children}
      </p>
    );
  }

  function a(props) {
    const isAnchor = props.href.indexOf('#') !== -1;
    let href = (!isAnchor ? props.href.replace('./', '/') : props.href).replace('api', 'modelo').replace('.md', '');

    if (isAnchor) {
      href = getLast() + '/' + href;
    }

    if (!href.startsWith('/')) {
      href = '/' + href;
    }

    const paths = href.split('/').filter(Boolean);
    const redirectedUrl = urlRedirects[`/${paths[paths.length - 1]}/`];
    if (redirectedUrl) {
      paths.pop();
      href = paths.join('/') + redirectedUrl;
    }

    return <Link {...props} to={href} />;
  }

  function th(props) {
    return <th {...props} className="border w-1/3 p-2 text-left" />;
  }

  function tr(props) {
    return <tr {...props} className="border" />;
  }

  function td(props) {
    return <td {...props} className="border w-1/3 p-2" />;
  }

  function table(props) {
    return <table {...props} className="w-full" />;
  }

  function b(props) {
    return <b {...props} className="mt-4 inline-block" />;
  }

  const renderAst = new Rehype({
    createElement: React.createElement,
    components: {
      h1,
      h2,
      h3,
      p,
      a,
      table,
      tr,
      td,
      th,
      b
    }
  }).Compiler;

  return <div className="container mx-auto">{renderAst(doc.htmlAst)}</div>;
};

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      htmlAst
    }
  }
`;
